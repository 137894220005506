<template>
  <v-tooltip bottom v-if="parsedDate">
    <template #activator="{ on }">
      <span class="date" v-on="on">{{ formattedDate }}</span>
      <span class="time ml-1" v-if="includeTime" v-on="on">{{
        formattedTime
      }}</span>
    </template>
    <span>
      {{ $t("label.date.isoDateInfo") }}
      <div
        class="pt-3"
        v-html="$t('label.date.isoDateDetail', { value: reformattedDate })"
      ></div>
    </span>
  </v-tooltip>
  <span v-else-if="placeholder">{{ placeholder }}</span>
</template>
<script>
import { format, formatISO } from "date-fns";
import { parseDateTime } from "../lib/dates";

export default {
  name: "IsoDate",
  props: {
    date: { required: true },
    includeTime: { type: Boolean, default: false },
    placeholder: { type: String, default: "", required: false },
  },

  computed: {
    includeDay() {
      if (!this.date) return false;
      // if the date contains two -, it is full, if only one, it is just year-month
      return this.date.split("-").length >= 3;
    },
    parsedDate() {
      if (!this.date) return null;
      return parseDateTime(this.includeDay ? this.date : this.date + "-01");
    },
    reformattedDate() {
      if (!this.parsedDate) return "";
      return format(
        this.parsedDate,
        this.includeTime ? "PPpp" : this.includeDay ? "PP" : "LLL yyyy"
      );
    },
    formattedDate() {
      if (!this.parsedDate) return "";
      if (this.includeDay)
        return formatISO(this.parsedDate, { representation: "date" });
      return formatISO(this.parsedDate, { representation: "date" }).slice(0, 7);
    },
    formattedTime() {
      if (!this.parsedDate) return "";
      return format(this.parsedDate, "HH:mm:ss");
    },
  },
};
</script>
